import { application } from './application';

import FormController from './form_controller';

import hideOnToggleController from './hide_on_toggle_controller';

import ModalController from './modal_controller';

import SortableController from './sortable_controller';

import ClipboardController from './clipboard_controller';

import LanguageSelectorController from './language_selector_controller';

application.register('form', FormController);
application.register('hide-on-toggle', hideOnToggleController);
application.register('modal', ModalController);
application.register('sortable', SortableController);
application.register('clipboard', ClipboardController);
application.register('language-selector', LanguageSelectorController);
