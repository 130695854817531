import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['languageSelect', 'translatableField'];

  connect() {
    requestAnimationFrame(() => {
      this.showSelectedLanguageFields();
    });
  }

  showSelectedLanguageFields() {
    const selectedLanguageId = this.languageSelectTarget.value;

    this.translatableFieldTargets.forEach((fieldWrapper) => {
      const languageId = fieldWrapper.dataset.languageId;
      fieldWrapper.style.display =
        languageId === selectedLanguageId ? 'block' : 'none';
    });
  }

  change() {
    this.showSelectedLanguageFields();
  }
}
